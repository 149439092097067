<template>
    <router-view></router-view>
</template>
<script setup>

</script>
<style>
#__vconsole .vc-switch{
    visibility: hidden;
}
.popup{
    position: fixed;
    background:#ddd;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
}
.popup-con{
    width: 320px;
    background:#fff;
    border-radius: 16px;
    text-align: center;
    padding: 30px 25px;
}
.popup-title{
    font-size: 20px;
    color: #5e5e5e;
}
.popup-text{
    font-size: 14px;
    margin: 10px 0 26px;
    color: #5e5e5e;
}
.popup-btn{
    background: rgb(16, 152, 231);
    color:#fff;
    height: 40px;
    width: 100%;
    border:none;
    border-radius: 5px;
}

.wrap {
	display: flex;
    flex-direction: column;
	height: 100vh;
	background-size: 100% 100%;
    align-items: center;
    justify-content: center;
    background-color: #97ccde;
}

.disabled-btn{
    width: 100px;
    height: 100px;
    background-color: #999;
    color: #666;
    border-radius: 50%;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    flex-shrink: 0;
    position: fixed;
    bottom: 20px;
    z-index: 2;
    user-select: none;
    -webkit-touch-callout: none;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    -webkit-tap-highlight-color:rgba(255,254,254,0)
}

.disabled-btn:hover{
    cursor:not-allowed;
}

.start-btn{
    width: 100px;
    height: 100px;
    background: linear-gradient(270deg, #4ac3c9 0%, #073cff 100%);
    border-radius: 50%;
    font-size: 16px;
    color:#fff;
    font-weight: bold;
    cursor: pointer;
    flex-shrink: 0;
    position: fixed;
    bottom: 20px;
    z-index: 2;
    user-select: none;
    -webkit-touch-callout: none;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    -webkit-tap-highlight-color:rgba(255,254,254,0)
}
.start-btn:active{
   bottom: 18px;
}

.video-wrap{
    text-align: center;
    width: 100%;
    flex: 1;
    overflow: hidden;
}

.video-wrap .digital{
    width: 100%;
    height: 100%;
    /* object-fit:cover; */
}

@media screen and (max-aspect-ratio: 1/1) {
    .video-wrap .digital{
        /* width: 100%;
        height: 100%; */
        object-fit: cover;
    }
}

.history-wrap{
    position: absolute;
    top: 30px;
    bottom: 30px;
    right: 30px;
    width: 380px;
    z-index: 10;
}
.history{
    width: 100%;
    height: 100%;
    background: #fff;
    border-image: radial-gradient(circle,rgba(255,254,254,.73),rgba(255,254,254,0)) 1 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
}
.history .header{
    padding: 0 15px;
}
.history .title{
    border-bottom: 1px solid rgba(167,179,193,.15);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #1d2129;
    height: 64px;
}
.close-history{
    width: 24px;
    height: 24px;
    cursor: pointer;
}
@media screen and (max-width: 1200px){
    .history-wrap{
        bottom: 0;
        left: 0;
        top: auto;
        width: 100%;
        height: 50%;
    }
    .tips .tips-con{
        min-width: 80%;
    }
}

.content{
    flex: 1;
    overflow-y: auto;
    padding: 14px 16px;
    display: flex;
    flex-direction: column;
    gap: 18px;
}
.history-item{
    display: flex;
}
.history-item.right{
    justify-content: flex-end;
}
.history-item span{
    padding: 6px 16px;
    background: #f4f9ff;
    color: #86909c;
    line-height: 14px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 6px;
}
.history-item.right span{
    color: #f2f3f5;
    background: #c79769;
}
.tag{
    position: absolute;
    right: 30px;
    top: 40px;
    width: 28px;
    height: 28px;
    background: #cb9c6d;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
}
.tag img{
    width: 16px;
}

</style>