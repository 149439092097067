import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            redirect: 'index',
        },
        {
            path: "/index",
            name: "index",
            component: () => import("../views/index.vue")
        },
        {
            path: "/child",
            name: "child",
            component: () => import("../views/components/expertAskingModel.vue")
        },
        {
            path: "/select",
            name: "select",
            component: () => import("../views/child.vue")
        },
        {
            path: "/man",
            name: "man",
            component: () => import("../views/man.vue")
        },
        {
            path: "/wxTips",
            name: "wxTips",
            component: () => import("../views/wxTips.vue")
        }
    ]
})
// let isCheck = false
// function isWeixin() {
//     isCheck = true
//     var ua = window.navigator.userAgent.toLowerCase();
//     return ua.indexOf("micromessenger") !== -1;
// }
// router.beforeEach((to, from, next) => {
//     if(isWeixin()){
//         if(to.path != '/wxTips'){
//             next("/wxTips");
//         }else{
//             next()
//         }
//     }else{
//         if(to.path == '/wxTips'){
//             next('/index')
//         }else{
//             next()
//         }
//     }
    
// })
export default router